<script>
export default {
  name: 'businessMain'
}
</script>
<template>
  <div class="business enter">
    <section class="section1">
      <div class="container">
        <div class="text_wrap t_white">
          <p class="sub_title">BUSINESS</p>
          <h1 class="cont_title">{{ $t("busi_gen.sec1_title")/* 인간의 정교함에 */ }}<br>{{ $t("busi_gen.sec1_title2")/* 기술의 효율을
            담았습니다. */}}</h1>
        </div>
      </div>
    </section>
    <section class="section2">
      <div class="container">
        <p class="cont_title">{{ $t("busi_gen.sec2_title")/* 렉스코드가 제공하는 서비스를 소개합니다. */ }}</p>
        <div class="flex">
          <div class="sec2_cont lx">
            <div class="sec2_bg">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/zib458h1rn0"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </div>
            <a href="/business/lex-cloud" class="text_wrap flex">
              <p class="cont_subtitle">LEX-Cloud</p>
              <p class="cont_service">{{ $t("menu.lc_subtitle") }}</p>
              <p class="cont_description">{{ $t("busi_gen.lc_desc_busi")/* 인공지능과 전문가의 협업으로 30% 빠른 속도, 20% 이상 절감된 비용으로 더욱
                전문적인 번역 서비스를 만나 보세요. 이메일 · 전화가 필요 없는 클라우드 플랫폼을 활용하여 견적부터 납품까지 차곡차곡 정리해 드려요. */}}</p>
            </a>
          </div>
          <a href="/business/tc" class="sec2_cont deepread">
            <div class="sec2_bg">
              <img src="../assets/img/business_deepread_section4_img1.png" />
            </div>
            <div class="text_wrap flex">
              <p class="cont_subtitle">TransCreation</p>
              <p class="cont_service">{{ $t("menu.tc_subtitle") }}</p>
              <p class="cont_description">{{ $t("busi_gen.gas_desc_busi")/* 생성형 인공지능과 자연어처리 기술의 조합으로 업무에 필요한 시간과 비용은 50%
                이상 줄이고, 텍스트에 담긴 무한한 가치를 필요한 데이터로 전환시킬 수 있습니다. */}}</p>
            </div>
          </a>
          <a href="/business/cat-x" class="sec2_cont catx">
            <div class="sec2_bg">
            </div>
            <div class="text_wrap flex">
              <p class="cont_subtitle">CAT-X</p>
              <p class="cont_service">{{ $t("menu.catx_subtitle") }}</p>
              <p class="cont_description">{{ $t("busi_gen.catx_desc_busi")/* 우리 회사에 맞춘 인공지능 자동번역 모델이 어떤 가치를 가져다주는지 사례를
                알아보세요. 데이터 수집부터 최신 딥러닝 프레임워크 기반의 자동번역 모델 구축까지 완성해 드려요. */}}</p>
            </div>
          </a>
          <div class="sec2_cont journal">
            <div class="sec2_bg">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/OfDIh8J2_rs"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </div>
            <a href="/business/journallab" class="text_wrap flex">
              <p class="cont_subtitle">Journal Lab</p>
              <p class="cont_service">{{ $t("menu.jl_subtitle") }}</p>
              <p class="cont_description">{{ $t("busi_gen.jl_desc_busi")/* 자연어처리 모델 GPT 기반의 초록 분석 시스템 DeepRead와 함께 시작하세요!
                SCIE급 저널 게재를 위한 가장 합리적인 패키지로 교정부터 번역, 포매팅까지. 전문가와 인공지능이 협업하여 여러분의 연구를 연구합니다. */}}</p>
            </a>
          </div>
          <div class="sec2_cont bv">
            <div class="sec2_bg">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/q-XKryd9hM0"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </div>
            <a href="/business/bavl" class="text_wrap flex">
              <p class="cont_subtitle">BAVL</p>
              <p class="cont_service">{{ $t("menu.bavl_subtitle") }}</p>
              <p class="cont_description">{{ $t("busi_gen.bavl_desc_busi")/* 언어 데이터의 가능성 BAVL을 사용해 보세요. 다양한 연구 기관 및 산업에
                필요한 언어 데이터를 텍스트와 음성의 제한 없이 합법적이고 안전하게 수집합니다. 언어 데이터를 통해 경험을 쌓고, 지식으로 활용해 또 다른 가능성을 만들어 보세요 */}}</p>
            </a>
          </div>
        </div>
      </div>
    </section>
    <div class="header_bg"></div>
  </div>
</template>
